body {
  margin: 0;
  font-family: Inter, 'Helvetica Neue', sans-serif;
  color: #121212;
  padding: 0;
  background-color: #F7F8FA;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}