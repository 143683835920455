.dispenser {
  display: flex;
  min-height: 100vh;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.dispenser__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 96px 32px 32px;
  gap: 32px;
  max-width: 452px;
  width: 100%;
  height: auto;
  background: #FFF;
  box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);
  border-radius: 24px;
}

.dispenser-iframe {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  background: #FFF;
  min-height: 100vh;
}

.qr-code {
  opacity: 1;
  transition: opacity 1s;
  min-height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.qr-code_fade {
  opacity: 0;
}

.dispenser__title {
  font-size: 22px;
  
  text-align: center;
  line-height: 28px;
  margin: 0;
  font-weight: 500;
}