.footer {
  display: flex;
  align-items: center;
  gap: 6px;
}

.footer__text {
  font-size: 13px;
  font-weight: 500;
}

.footer__logo {
  max-width: 86px;
}