@font-face {
  font-family: 'Inter';
  font-weight: 400;
  src: url('./Inter-Regular.woff2') format('woff2'),
       url('./Inter-Regular.woff') format('woff');
}


@font-face {
  font-family: 'Inter';
  font-weight: 500;
  src: url('./Inter-Medium.woff2') format('woff2'),
       url('./Inter-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  src: url('./Inter-SemiBold.woff2') format('woff2'),
       url('./Inter-SemiBold.woff') format('woff');
}
