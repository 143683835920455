.progress-bar {
  max-width: 212px;
  width: 100%;
  margin: 0 auto;
}

.progress-bar__wrapper {
  position: relative;
  height: 8px;
  border-radius: 24px;
  overflow: hidden;
  background-color: #E4E4E4;
}

.progress-bar__value {
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  background-color: #0052FF;
  transition: width 1s linear;
}